<template>
  <div class="beneficiary-physical">
    <span class="physical-benef-title">
      {{ isEdit ? "Modifier" : "Ajouter" }} un bénéficiaire personne
      physique</span
    >
    <div class="giga-fields benef-form">
      <div
        :class="!beneficiary.name.required ? 'field-isRequired' : ''"
        class="giga-input_field col-lg-4"
      >
        <label class="input-field_label">{{ $t("first_name") }}
          <span class="text-danger">*</span>
        </label>
        <div class="input-field_area">
          <input
            type="text"
            id="name"
            :class="
              beneficiary.name.$anyDirty && beneficiary.name.$invalid
                ? 'field-hasError'
                : ''
            "
            v-model="beneficiary.name.$model"
            class="small-input"
            :disabled="isVIEWER"
          />
          <div
            class="validation-field-holder"
            v-if="beneficiary.name.$anyDirty && beneficiary.name.$invalid"
          >
            <small v-if="!beneficiary.name.required">
              {{ $t("required_field") }}
            </small>
          </div>
        </div>
      </div>

      <div
        :class="!beneficiary.lastName.required ? 'field-isRequired' : ''"
        class="giga-input_field col-lg-4"
      >
        <label class="input-field_label">{{ $t("last_name") }}
          <span class="text-danger">*</span>
        </label>
        <div class="input-field_area">
          <input
            type="text"
            id="lastName"
            :class="
              beneficiary.lastName.$anyDirty && beneficiary.lastName.$invalid
                ? 'field-hasError'
                : ''
            "
            v-model="beneficiary.lastName.$model"
            class="small-input"
            :disabled="isVIEWER"
          />
          <div
            class="validation-field-holder"
            v-if="
              beneficiary.lastName.$anyDirty && beneficiary.lastName.$invalid
            "
          >
            <small v-if="!beneficiary.lastName.required">
              {{ $t("required_field") }}
            </small>
          </div>
        </div>
      </div>

      <div
        :class="
          !beneficiary.natureOccupation.required ? 'field-isRequired' : ''
        "
        class="giga-input_field col-lg-4"
      >
        <label class="input-field_label">{{ $t("occupation_nature") }}
          <span class="text-danger">*</span>
        </label>
        <div class="input-field_area">
          <select
            id="natureOccupation"
            :class="
              beneficiary.natureOccupation.$anyDirty &&
              beneficiary.natureOccupation.$invalid
                ? 'field-hasError'
                : ''
            "
            v-model="beneficiary.natureOccupation.$model"
            class="small-input"
            :disabled="isVIEWER"
          >
            <option key="PROPRIETAIRE" value="PROPRIETAIRE"
              >Propriétaire</option
            >
            <option key="LOCATAIRE" value="LOCATAIRE">Locataire</option>
          </select>
          <div
            class="validation-field-holder"
            v-if="
              beneficiary.natureOccupation.$anyDirty &&
                beneficiary.natureOccupation.$invalid
            "
          >
            <small v-if="!beneficiary.natureOccupation.required">
              {{ $t("required_field") }}
            </small>
          </div>
        </div>
      </div>

      <div
        :class="!beneficiary.tele1.required ? 'field-isRequired' : ''"
        class="giga-input_field col-lg-6"
      >
        <label class="input-field_label">{{ $t("phone") }} 1
          <span class="text-danger">*</span>
        </label>
        <div class="input-field_area">
          <input
            type="tel"
            id="phone1"
            :class="
              beneficiary.tele1.$anyDirty && beneficiary.tele1.$invalid
                ? 'field-hasError'
                : ''
            "
            v-model="beneficiary.tele1.$model"
            class="small-input"
            :disabled="isVIEWER"
          />
          <div
            class="validation-field-holder"
            v-if="beneficiary.tele1.$anyDirty && beneficiary.tele1.$invalid"
          >
            <small v-if="!beneficiary.tele1.required">
              {{ $t("required_field") }}
            </small>
          </div>
        </div>
      </div>

      <div
        :class="!beneficiary.tele2.required ? 'field-isRequired' : ''"
        class="giga-input_field col-lg-6"
      >
        <label class="input-field_label">{{ $t("phone") }} 2
        </label>
        <div class="input-field_area">
          <input
            type="tel"
            id="phone2"
            :class="
              beneficiary.tele2.$anyDirty && beneficiary.tele2.$invalid
                ? 'field-hasError'
                : ''
            "
            v-model="beneficiary.tele2.$model"
            class="small-input"
            :disabled="isVIEWER"
          />
          <div
            class="validation-field-holder"
            v-if="beneficiary.tele2.$anyDirty && beneficiary.tele2.$invalid"
          >
            <small v-if="!beneficiary.tele2.required">
              {{ $t("required_field") }}
            </small>
          </div>
        </div>
      </div>

      <div
        :class="!beneficiary.email.required ? 'field-isRequired' : ''"
        class="giga-input_field col-lg-6"
      >
        <label class="input-field_label">{{ $t("email") }}
          <span class="text-danger">*</span>
        </label>
        <div class="input-field_area">
          <input
            type="text"
            id="email"
            :class="
              beneficiary.email.$anyDirty && beneficiary.email.$invalid
                ? 'field-hasError'
                : ''
            "
            v-model="beneficiary.email.$model"
            class="small-input"
            :disabled="isVIEWER"
            @input="debouncedCheckValidityEmail"
          />
          <div
            class="validation-field-holder"
            v-if="beneficiary.email.$anyDirty && beneficiary.email.$invalid"
          >
            <small v-if="!beneficiary.email.required">
              {{ $t("required_field") }}
            </small>
          </div>
        </div>
      </div>
      <!--<div class="giga-input_field col-lg-6">
        <label class="input-field_label">{{ $t("status") }}</label>
        <div class="input-field_area">
          <div v-if="emailStatus">
            <div v-if="emailStatus === 'loading'">
              <div class="email-status">
                <img
                  class="icon-status"
                  src="@/assets/images/spinner.svg"
                  alt=""
                />
                <span class="label-status">Vérification en cours</span>
              </div>
            </div>
            <div v-else-if="emailStatus === 'Active'">
              <div class="email-status">
                <img
                  class="custom-icon"
                  src="@/assets/images/check-list.svg"
                  alt=""
                />
                <span class="label-status">Actif</span>
              </div>
            </div>
            <div v-else-if="emailStatus === 'Unspecified'">
              <div class="email-status">
                <img
                  class="custom-icon"
                  src="@/assets/images/alert-notif.svg"
                  alt=""
                />
                <span class="label-status">Non spécifié</span>
              </div>
            </div>
            <div v-else-if="emailStatus === 'Invalid_Syntax'">
              <div class="email-status">
                <img
                  class="custom-icon"
                  src="@/assets/images/alert-notif.svg"
                  alt=""
                />
                <span class="label-status">Syntaxe invalide</span>
              </div>
            </div>
            <div v-else-if="emailStatus === 'Inactive'">
              <div class="email-status">
                <img
                  class="custom-icon"
                  src="@/assets/images/alert-notif.svg"
                  alt=""
                />
                <span class="label-status">Inactif</span>
              </div>
            </div>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import { contactsCraftService } from "@/views/Simulations/services/contacts-craft.service";
import { debounce } from "lodash";

export default {
  name: "BeneficiaryPhysical",
  data() {
    return {
      emailStatus: ""
    };
  },
  created() {
    this.debouncedCheckValidityEmail = debounce(this.checkValidityEmail, 1000);
  },
  props: ["isVIEWER", "beneficiary", "isEdit"],
  methods: {
    checkValidityEmail(event) {
      const email = event.target.value;
      if (email && !this.beneficiary.email.$invalid) {
        this.emailStatus = "loading";
        contactsCraftService.getEmailsStatus([email]).then(emails => {
          if (emails.length > 0) {
            this.emailStatus = emails[0].status;
          } else {
            this.emailStatus = undefined;
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.beneficiary-physical {
  padding: 40px;
  border-radius: 6px;
  box-shadow: 0 0 25px 0 rgba(52, 74, 87, 0.05);
  border: solid 1px #c8d4df;
  background-color: #fff;
}
.physical-benef-title {
  font-weight: 500;
  color: #143a5a;
}
.benef-form {
  margin-top: 20px;
}
input {
  background-color: white !important;
}
.email-status {
  display: flex;
  align-items: center;
}
.icon-status {
  width: 10%;
}
.label-status {
  font-size: smaller;
}
.custom-icon {
  width: 8%;
  margin-right: 8px;
}
</style>
